import React from 'react'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import styled from '@emotion/styled'

const Container = styled('div')`
  color: white;
  text-align: center;
  padding: 186px 0 42px;
  h2 {
    font-size: 70px;
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-weight: 500;
  }
  p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 30px;
    margin-top: 5px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <StickyNav />
    <Container>
      <h2>404</h2>
      <p>Sorry, page not found</p>
    </Container>
  </Layout>
)

export default NotFoundPage
